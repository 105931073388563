import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { environment } from 'src/environments/environment';
/**
 * The Service to load the Config via the MCP
 */
@Injectable({
  providedIn: 'root'
})
export class McpService {
  /**
   * The config as a BehaviorSubject
   */
  configuration$: BehaviorSubject<any>;
  /**
   * constructor
   * @param http 
   * @param env 
   */
  constructor(private http: HttpClient,
    private env: EnvironmentService) {

  }
  /**
   * Get all Client via the MCP
   * @returns 
   */
  getClients() {
    return this.http.get(`${environment.MCP_CLIENTS_JSON}`)
  }
  /**
   * Get the Properties fromt the MCP
   * @returns 
   */
  getClientProperties() {
    return this.http.get(`${environment.mcp}client/${this.env.getClientId()}/property`)
  }
  /**
   * Load the Config for the current Mandant and convert ot to an Object via keyValueArrayToObject
   * @returns 
   */
  loadConfiguration(): Observable<any> {
    if (this.env.getClientId()) {
      return this.http.get(`${environment.mcp}client/${this.env.getClientId()}/property`).pipe(
        map(config => this.keyValueArrayToObject(config)),
        map((config: any) => {
          return config
        })
      )
    } else {
      return throwError(() => "No ClientID set!");
    }

  }
  /**
   * Convert the Config to an Object
   * @param config 
   * @returns 
   */
  keyValueArrayToObject(config): {} {
    let newObject = {}
    if (Array.isArray(config)) {
      // convert MCP structure to object
      config.forEach(c => {
        if (c.key === 'mandantid') {
          newObject[c.key] = +c.value;
        } else if (c.key === 'faq' || c.key === 'support_hours' || c.key == 'bank_data' || c.key == 'bank_data_2' || c.key == 'newsletter' || c.key == 'SB_MENU' || c.key == 'Partner_Menu' || c.key == 'Admin_Menu') {
          newObject[c.key] = JSON.parse(c.value);
        } else if (c.value.toLowerCase() == 'true' || c.value.toLowerCase() == 'false') {
          newObject[c.key] = JSON.parse(c.value)
        } else {
          newObject[c.key] = c.value
        }
      });
    } else {
      config = config;
    }
    return newObject
  }
  /**
   * Load a config with an ID
   * @param id 
   * @returns 
   */
  loadConfigurationWithID(id: number): Observable<any> {
    return this.http.get(`https://mcp.swaas.de/client/${id}/property`).pipe(
      map(config => this.keyValueArrayToObject(config)),
      map((config: any) => {
        return config
      })
    )
  }
}
